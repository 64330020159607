#background-container {
  background-image: url('./assets/gingham\ bg-01.jpg'); /* Adjust the path */
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#paper-container {
  margin-top: 50%;
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

#paper-container > div > div > div > div {
  margin-left: 0px !important;
  margin-top: 0px !important;
  height: 50px !important;
}
