#main {
  text-align: center;
  background-image: url('../../assets/gingham\ bg-01.jpg'); /* Adjust the path */
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 125vh;
  z-index: -1;
}

#main-form-container {
  margin-top: 50%;
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

#default-store-form-container {
  margin-top: 10%;
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}
